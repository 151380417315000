/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-03-13",
    versionChannel: "nightly",
    buildDate: "2023-03-13T00:19:09.191Z",
    commitHash: "b527054a1e2317d9e17ed0dfbfc0e9cceaafd760",
};
